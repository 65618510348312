import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Doodle Dragon</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Doodle Dragon is an innovative platform that uses AI to turn children’s sketches into interactive learning adventures. It adjusts to each child's developmental level, offering customized educational content through games and stories. Safe and engaging, Doodle Dragon fosters creativity while reinforcing key concepts in a secure, ad-free environment. This tool not only entertains but also educates, making it a perfect blend of fun and learning.
                                </p>
                                <p>Accessible on Android, iOS, and Web platforms with Flutter frontend.</p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Join Our Beta Program
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Mind Engage</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Mind Engage is an innovative educational platform that harnesses the advanced capabilities of NVIDIA's NIM and LangGraph AI technologies to tailor learning experiences to individual student needs. This platform dynamically adjusts content and pacing based on real-time performance data, ensuring that each learner receives instruction that is optimally challenging and engaging. With a focus on enhancing understanding and retention, Mind Engage supports a wide range of subjects from mathematics to language arts. The result is a deeply personalized education journey that promotes greater learning efficiency and effectiveness.
                                </p>
                                <p>Accessible on Android, iOS, and Web platforms with Flutter frontend.</p>
                                <div className="flex justify-center my-4">
                                    <a href="https://mind-engage.web.app/" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Try it now
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </a>
                                </div>
                                
                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">IntelliMesh</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                IntelliMesh is a decentralized network that optimizes video processing by leveraging distributed computing resources and Generative AI multimodal models, enhancing both speed and scalability. It supports real-time, efficient analysis of video data, crucial for industries requiring quick response times such as surveillance and autonomous driving. By distributing workloads across various nodes, IntelliMesh ensures robust performance and enhances system reliability. This advanced framework provides a secure and adaptable solution for handling complex video processing tasks in a wide range of applications.
                                </p>
                                <p>Rust implementation with Polkadot Substrate and distributed Generative AI inference</p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>             
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;